export function getPlaylistId(from) {
  const match = from.match(/(list=([A-Za-z0-9_-]*)|^[A-Za-z0-9_-]*$)/) || []

  return match[2] || match[1] || ''
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function sort(players, sortierer) {
  for (var i = 1; i < players.length; i++) {
    let temp = players[i];
    let placeHolderIndex = i;
    while ((placeHolderIndex > 0) && (players[placeHolderIndex - 1][sortierer] < temp[sortierer])) {
      players[placeHolderIndex] = players[placeHolderIndex - 1];
      placeHolderIndex--;
    }
    players[placeHolderIndex] = temp;
  }
}

const colors = shuffle([
  '#00d6d6',
  //'#f1c40f',
  '#E9FF00',//lemon lime
  //'#8e44ad',//dunkles violette
  //'#2ecc71',
  //'#FFFF00',
  '#FF3300',//geil
  //'#2BB1FF', //blau
  '#00A1FF', //blau mehr saturation
  //'#fd9dff',//nicht so geil
  //'#EE34D2',//geil magenta
  '#ED00C9',//magenta mehr saturation geil^3
  '#8300FF',//violett auch geil!
  '#00ff96',//geil
  '#2d6969',//?
  '#1c0035',//dunkelbalu
  '#FF7000',
  '#1eff1e'
])

let currentColor = 0

export function nextColor () {
  currentColor %= colors.length
  return colors[currentColor++]
}