<template>
  <div id="app_inside" :class="mode">
    <div id="create" @click="createRoom">
      <div class="button" @click="createRoom">CREATE</div>
      <Host v-if="mode == 'host'" />
    </div>
    <div id="join">
      <div class="button" @click="joinRoom">JOIN</div>
      <Client v-if="mode == 'client'" />
    </div>
  </div>
</template>

<script>
import Host from "./components/Host.vue"
import Client from "./components/Client.vue"

export default {
  name: "app_inside",
  components: { Host, Client },
  data() {
    return {
      mode: "",
    };
  },
  methods: {
    createRoom() {
      this.mode = "host";
    },
    joinRoom() {
      this.mode = "client";
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

* {
  /* font-family: "Alegreya Sans", cursive; */
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#app_inside {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 4em;
}

#app_inside.host>#join,
#app_inside.client>#create {
  flex: 0;
  width: 0;
}

.button {
  transition: line-height .5s, height .5s, opacity .5s;
}

#app_inside.host>#create>.button,
#app_inside.client>#join>.button {
  line-height: 140%;
  height: auto;
  cursor: default;
}

.form>* {
  display: inline !important;
}

#app_inside.host>#create>.button {
  height: 0;
  opacity: 0;
}

#join,
#create {
  flex: 1;
  transition: flex .5s;
  height: 100%;
}

#join {
  background: #2c94fc;
  min-width: 0px;
}

#create {
  background: #ea4343;
  min-width: 0px;
}

#app_inside>div {
  color: white;
  text-decoration: none;
  /* font-family: monospace; */
  text-align: center;
  vertical-align: middle;
}

@media screen and (max-width: 1000px) {
  #app_inside {
    flex-direction: column;
  }

  #app_inside>div {
    width: 100%;
    height: 0;
  }

  #app_inside>div>.button {
    line-height: 50vh;
  }

  #app_inside.host>#join,
  #app_inside.client>#create {
    flex: 0;
    width: auto;
  }
}
</style>
