<template>
  <div id="ytplayer">
    <div id="yt-container"></div>
  </div>
</template>

<script>
import YouTubePlayer from 'youtube-player';


function getRandStart() {
  var start;
  if (Math.floor(Math.random() * 2) > 0) {
    start = Math.floor(Math.random() * (40 - 15 + 1)) + 15;
  } else {
    start = Math.floor(Math.random() * (90 - 70 + 1)) + 70;
  }
  return start;
}

// var player

// window.onYouTubeIframeAPIReady = function () {
//   console.log("PLAYER loading")
//   player = new window.YT.Player("yt-container", {
//     height: "360",
//     width: "200",
//     events: {
//       onReady: this.onReady,
//       onStateChange: this.onState,
//       onError: this.onError,
//     },
//   });
// }

export default {
  name: "YTPlayer",
  data() {
    return {
      player: null,
      volume: 0,
      playlistID: "PLVWTPE3fsw8X9FgmqwR5VdsasvKQZh39Y",
      playlistArray: [],
      arrayRunner: 0,
      api_key: "AIzaSyB0U1_4ejKNjW5YCpFnatL20OwmWSTYlHg",
      playlistURL: "",
      cueSingleReady: false,
    };
  },
  watch: {
    volume(vol) {
      this.player.setVolume(vol);
    },
  },
  mounted() {
    this.player = YouTubePlayer('yt-container');
    // window.onYouTubeIframeAPIReady(() => {
    //   this.player = new window.YT.Player("yt-container", {
    //     height: "360",
    //     width: "200",
    //     events: {
    //       onReady: this.onReady,
    //       onStateChange: this.onState,
    //       onError: this.onError,
    //     },
    //   });
    // })

  },
  methods: {

    onReady() { },
    onError() { },
    onState() { },

    getCurrentVideoID() {
      return this.player.getVideoUrl();
    },

    returnIFrame() {
      return this.player.getIframe();
    },

    async play(playlist) {
      this.playlistID = playlist;
      await this.getVideos();
    },

    cueSingle(VideoURL) {
      var breaker = 0;
      var success;
      const interval = setInterval(() => {
        success = true;
        try {
          this.player.setVolume(0);
          this.player.loadVideoById(VideoURL.split("v=")[1].split("&")[0]);
        } catch (error) {
          success = false;
          console.log(error);
        }
        if (success || breaker > 50) clearInterval(interval);
      }, 100);
      setTimeout(() => {
        this.player.pauseVideo();
      }, 1000);
    },

    pause() {
      this.volume = 0;
      this.player.pauseVideo();
    },

    next() {
      this.fadeOut();
      setTimeout(() => {
        this.player.loadVideoById(
          this.playlistArray[this.arrayRunner].url,
          getRandStart()
        );
        this.arrayRunner++;
        this.player.setVolume(0);
        setTimeout(() => {
          this.fadeIn();
        }, 2000);
      }, 500);
    },

    resume() {
      this.volume = 100;
      this.player.playVideo();
    },

    fadeIn(speed) {
      if (!speed) speed = 1;
      let x = 0;
      const interval = setInterval(() => {
        this.volume = (x * x) / 3;

        if (this.volume >= 100) clearInterval(interval);
        x += 1.2 * speed;
        //if(YT.PlayerState.PLAYING && this.volume>=100) this.$emit("startingSong",this.player.getVideoUrl())
      }, 100);
    },

    fadeOut() {
      let x = 0;
      const interval = setInterval(() => {
        let sub = x * x;
        this.volume = 100 - sub;

        if (sub >= 100) clearInterval(interval);
        x += 1.2;
      }, 80);
    },

    titleInterpreter() {
      return this.playlistArray[this.arrayRunner - 1].title;
    },

    songInfo(amount, offset) {
      if (!offset) offset = 0;
      var a = [];
      for (var i = 0; i < amount; i++) {
        a.push(this.playlistArray[this.arrayRunner - 1 + offset + i]);
      }
      //console.log(JSON.stringify(a))
      //console.log(JSON.parse(JSON.stringify(a)))
      return a;
    },

    async getVideos(t) {
      var url =
        "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=" +
        this.playlistID +
        "&key=" +
        this.api_key;
      if (t != undefined) url = url + "&pageToken=" + t;

      let res = await this.$http.get(url);
      for (let item of res.data.items) {
        //console.log(item.snippet.thumbnails)
        var thumb = "";
        try {
          thumb = item.snippet.thumbnails.default.url;
          thumb = item.snippet.thumbnails.high.url;
          thumb = item.snippet.thumbnails.maxres.url;
        } catch (error) {
          //ignore
          //console.log(error)
        }
        var obj = {
          url: item.snippet.resourceId.videoId,
          title: item.snippet.title,
          thumbnail: thumb,
        };
        this.playlistArray.push(obj);
      }
      this.$emit("bufferStatus", this.playlistArray.length);
      if (res.data.nextPageToken != undefined) {
        this.getVideos(res.data.nextPageToken);
      } else {
        this.$emit("bufferStatus", "0000");
        console.log(this.playlistArray);
        this.playlistArray = this.shuffle(this.playlistArray);
        //console.log(this.playlistArray)

        setTimeout(() => {
          this.player.loadVideoById(
            this.playlistArray[this.arrayRunner].url,
            getRandStart()
          );
          this.arrayRunner++;

          this.$emit("startingSong", this.songInfo(3, 0));

          this.player.setVolume(0);
          setTimeout(() => {
            this.$emit("bufferStatus", "done");
            this.fadeIn();
          }, 2000);
        }, 2000);
      }
    },

    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
  },
};
</script>

<style lang="stylus">
#ytplayer
  position: relative
  opacity: 1
  width: 100%
  height: 56.25vw

  iframe
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
</style>
