// import { DataConnection } from 'peerjs'
import { nextColor } from './util'
let playerCount = 0;

export default class Player {
  constructor (peer) {
    this.id = playerCount++;
    this.color = nextColor();
    this.name = 'Joining...';
    this.peer = peer;
    this.points = 0;
    this.previousBuzz = false;
    this.isGamemaster = false;
    this.cookie = '';
    this.falseAnswers = 0;
    this.answeringTimes =[];
    this.meanTime=100;
    this.ping = '';
    this.watch = 0
  }
}